import { Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {
  constructor(private service: AuthenticationService,
    private router: Router) {

  }

  ngOnInit(): void {
    this.logout();
  }

  logout(): void {
    this.service.logout();
    this.router.navigate(['/']).then(() => window.location.reload());
  }
}
