import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChildPlusStudent } from '../model/childplus-student';

@Injectable({
  providedIn: 'root'
})
export class StagingService {
  apiUrl: string = environment.apiUrl + "Staging/";
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) { }

  childPlusStudents(childPlusId: number) {
    return this.http.post<ChildPlusStudent[]>(this.apiUrl + "childplus-students", childPlusId, { headers: this.httpHeaders });
  }
}
