import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  apiUrl: string = environment.apiUrl + "Login/";

  constructor(private http: HttpClient) {

  }

  login(credentials: string): Observable<any> {
    const header = new HttpHeaders().set('Content-type', 'application/json');
    var response = this.http.post(this.apiUrl + "access", JSON.stringify(credentials), { headers: header});
    return response;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
  }

}
