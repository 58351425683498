import { Injectable } from '@angular/core';
import { StudentFormComponent } from '../../student/student-form/student-form.component';
import {  CanDeactivateFn } from '@angular/router';

Injectable()
export const StudentFormGuard: CanDeactivateFn<StudentFormComponent> =
  (component: StudentFormComponent): boolean => {
    if (component.form.dirty) {
      return confirm('Are you sure you want to leave this page? If you do, any unsaved changes will be lost.');
    }
    return true;
}
