<body>
    <div class="page-wrap">
        <app-nav-menu></app-nav-menu>
        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </div>

  <app-footer></app-footer>
</body>
