<div class="layout-footer">
    <div class="footer-top">
        <div>
            <a [routerLink]="['/']" class="footer-logo">
                <img src="assets/images/cnmi-logo.png" alt="cnmi-logo" />
            </a>
        </div>
        <div>
            <span class="footer-menutitle">CONTACT DBDriven</span>
            <ul>
                <li>(323) 784-0007</li>
                <li>100 Riverside Parkway, Suite 201</li>
                <li>Fredericksburg, Virginia 22406</li>
            </ul>
        </div>
        <div>
            <span class="footer-menutitle">CNMI PSS</span>
            <span class="footer-subtitle">For the official website, please visit:</span>
            <a href="https://www.cnmipss.org/">https://www.cnmipss.org/</a>
        </div>
    </div>
    <div>
        <div class="footer-bottom">
            <h6>Copyright Ⓒ DBDriven.net, LLC</h6>
        </div>
    </div>
</div>

