import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { NoteType } from '../model/note-type';

@Injectable({
  providedIn: 'root'
})
export class ValidValueService {

  apiUrl: string = environment.apiUrl + "ValidValue/";

  genders: string[] = [];
  federalRaces: string[] = [];
  localRaces: string[] = [];
  coordinators: string[] = [];
  locations: string[] = [];
  statuses: string[] = [];
  sources: string[] = [];
  exitReasons: string[] = [];
  noteTypes: NoteType[] = [];

  constructor(private http: HttpClient) {
    this.getFederalRaces().subscribe({
      next: (races) => { this.federalRaces = races },
      error: (err) => { console.log(err) }
    });

    this.getLocalRaces().subscribe({
      next: (races) => { this.localRaces = races },
      error: (err) => { console.log(err) }
    });

    this.getGenders().subscribe({
      next: (genders) => { this.genders = genders },
      error: (err) => { console.log(err) }
    });

    this.getServiceCoordinators().subscribe({
      next: (coordinators) => { this.coordinators = coordinators },
      error: (err) => { console.log(err) }
    });

    this.getServiceLocations().subscribe({
      next: (locations) => { this.locations = locations },
      error: (err) => { console.log(err) }
    });

    this.getEligibilityStatuses().subscribe({
      next: (statuses) => { this.statuses = statuses },
      error: (err) => { console.log(err) }
    });

    this.getReferralSources().subscribe({
      next: (sources) => { this.sources = sources },
      error: (err) => { console.log(err) }
    });

    this.getExitReasons().subscribe({
      next: (reasons) => { this.exitReasons = reasons },
      error: (err) => { console.log(err) }
    });

    this.getNoteTypes().subscribe({
      next: (types) => { this.noteTypes = types },
      error: (err) => { console.log(err) }
    });
  }


  getFederalRaces(): Observable<string[]> {
    var races = this.http.get<string[]>(this.apiUrl + "federal-races");
    return races;
  }

  getLocalRaces(): Observable<string[]> {
    var races = this.http.get<string[]>(this.apiUrl + "local-races");
    return races;
  }

  getGenders(): Observable<string[]> {
    var genders = this.http.get<string[]>(this.apiUrl + "genders");
    return genders;
  }

  getServiceCoordinators(): Observable<string[]> {
    var coordinators = this.http.get<string[]>(this.apiUrl + "service-coordinators");
    return coordinators;
  }

  getServiceLocations(): Observable<string[]> {
    var locations = this.http.get<string[]>(this.apiUrl + "service-locations");
    return locations;
  }

  getEligibilityStatuses(): Observable<string[]> {
    var statuses = this.http.get<string[]>(this.apiUrl + "eligibility-statuses");
    return statuses;
  }

  getReferralSources(): Observable<string[]> {
    var sources = this.http.get<string[]>(this.apiUrl + "referral-sources");
    return sources;
  }

  getExitReasons(): Observable<string[]> {
    var reasons = this.http.get<string[]>(this.apiUrl + "exit-reasons");
    return reasons;
  }

  getNoteTypes(): Observable<NoteType[]> {
    var noteTypes = this.http.get<NoteType[]>(this.apiUrl + "note-types");
    return noteTypes;
  }
}
