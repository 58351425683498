import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  apiUrl: string = environment.apiUrl + "File/";
  httpHeaders = new HttpHeaders({
      'Accept': 'text/html, application/xhtml+xml, */*, application/octet-stream',
      'Content-Type': 'application/octet-stream'
    })
 
  constructor(private http: HttpClient) { }

  uploadNoteAttachment(studentId: number, attachment: File): Observable<File> {
      let formData = new FormData();
      formData.append(attachment.name, attachment)
      return this.http.post<File>(this.apiUrl + "upload-note-attachment/" + studentId, formData);
  }

  downloadNoteAttachment(studentId: number, attachment: string) {
    return this.http.get(this.apiUrl + "download-note-attachment/" + studentId + "/" + attachment, { responseType: 'blob', headers: this.httpHeaders });

  }
}
