<form #studentForm="ngForm" (ngSubmit)="SubmitStudent(studentForm)">
    <div class="form-headers">
        <div>
            <button mat-button type="button" (click)="accordion.openAll()">Expand All</button>
            <button mat-button type="button" (click)="accordion.closeAll()">Collapse All</button>
        </div>

        <div class="active">
            <mat-label *ngIf="student.isActive">Status: Active</mat-label>
            <mat-label *ngIf="!student.isActive">Status: Inactive</mat-label>
            <mat-slide-toggle [(ngModel)]="student.isActive" name="active"></mat-slide-toggle>
        </div>
    </div>
    <mat-accordion class="headers-align" multi="true">
        <!--Student Tab -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Student
                </mat-panel-title>
                <mat-panel-description>
                    Demographic Information
                    <mat-icon>account_circle</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-section">
                <div *ngIf="student.id">
                    <mat-form-field>
                        <mat-label>Id</mat-label>
                        <input matInput id="sid" type="text" [(ngModel)]="student.id" name="Student Id" disabled />
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Child Plus Id</mat-label>
                        <input matInput id="cpid" type="number" (input)="this.childPlusStudentSuggestion()" [(ngModel)]="student.childPlusId" name="ChildPlus Id" [matAutocomplete]="auto" />
                        <mat-autocomplete [panelWidth]="'auto'" #auto="matAutocomplete">
                            <mat-option *ngFor="let student of this.childPlusStudents" [value]="student.childPlusId" (click)="selectChildPlusStudent(student)">
                                {{student.childPlusId}}, {{student.firstName}} {{student.lastName}}, {{student.birthDate | date: 'MM/dd/YYYY'}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>First Name</mat-label>
                        <input matInput id="fname" type="text" [(ngModel)]="student.firstName" name="First Name" #fname="ngModel" required />
                        <mat-error *ngIf="fname.invalid && fname.touched">First Name is Required</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Middle Name</mat-label>
                        <input matInput id="mname" type="text" [(ngModel)]="student.middleName" name="Middle Name" #fname="ngModel" />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Last Name</mat-label>
                        <input matInput id="lname" type="text" [(ngModel)]="student.lastName" name="Last Name" #lname="ngModel" required />
                        <mat-error *ngIf="lname.invalid">Last Name is Required</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Suffix</mat-label>
                        <input matInput id="gen" [(ngModel)]="student.suffix" name="suffix" #suff="ngModel">
                    </mat-form-field>
                </div>

                <div class="">
                    <mat-form-field>
                        <mat-label>Birth Date</mat-label>
                        <input matInput id="bdate" [matDatepicker]="birthdate" [max]="today" [(ngModel)]="student.birthDate" name="Birth Date" (dateChange)="populateBirthDate()" #bdate="ngModel" required>
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="birthdate"></mat-datepicker-toggle>
                        <mat-datepicker #birthdate></mat-datepicker>
                        <mat-error *ngIf="bdate.invalid">Birth Date is Required</mat-error>
                    </mat-form-field>
                </div>

                <div class="">
                    <mat-form-field>
                        <mat-label>Third Birthday</mat-label>
                        <input matInput id="thirdbdate" [matDatepicker]="thirdBirthday" [(ngModel)]="student.thirdBirthday" name="Third Birthday" #thirdbdate="ngModel" disabled>
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="thirdBirthday"></mat-datepicker-toggle>
                        <mat-datepicker #thirdBirthday></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="">
                    <mat-form-field>
                        <mat-label>Age (M)</mat-label>
                        <input matInput type="text" [(ngModel)]="student.age" name="Age" disabled>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Gender</mat-label>
                        <mat-select id="gen" [(ngModel)]="student.gender" name="gender" #gen="ngModel" required>
                            <mat-option *ngFor="let gender of this.validValueService.genders" [value]="gender">
                                {{gender}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="gen.invalid">Gender is Required</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="large-field">
                        <mat-label>Federal Race</mat-label>
                        <mat-select id="federal" [(ngModel)]="student.federalRace" name="Federal Race" #federal="ngModel" required>
                            <mat-option *ngFor="let race of this.validValueService.federalRaces" [value]="race">
                                {{race}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="federal.invalid">Federal Race is Required</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="large-field">
                        <mat-label>Local Race</mat-label>
                        <mat-select [(ngModel)]="student.localRace" name="Local Race">
                            <mat-option *ngFor="let race of this.validValueService.localRaces" [value]="race">
                                {{race}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>

        <!-- Referral Panel -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Referral
                </mat-panel-title>
                <mat-panel-description>
                    Evaluation, Measurement, and Exit Information
                    <mat-icon>note_alt</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="student.referrals.length >= 1">
                <h1 class="center">Referrals</h1>
                <div class="referrals-container">
                    <div>
                        <b>Coordinator</b>
                    </div>
                    <div>
                        <b>Location</b>
                    </div>
                    <div>
                        <b>Status</b>
                    </div>
                    <div>
                        <b>Source</b>
                    </div>
                    <div>
                        <b>Referral Date</b>
                    </div>
                    <div>
                        <b>Referral Age</b>
                    </div>
                    <div>
                        <b>Exit Date</b>
                    </div>
                    <div></div>

                    <ng-container *ngFor="let referral of student.referrals.reverse(); index as i" class="referrals-row">
                        <div>
                            {{referral.coordinator}}
                        </div>
                        <div>
                            {{referral.location}}
                        </div>
                        <div>
                            {{referral.status}}
                        </div>
                        <div>
                            {{referral.source}}
                        </div>
                        <div>
                            {{referral.date | date: 'MM/dd/yyyy'}}
                        </div>
                        <div>
                            {{referral.age}}
                        </div>
                        <div>
                            {{referral.exitDate | date: 'MM/dd/yyyy'}}
                        </div>
                        <div>
                            <button mat-button color="primary" type="button" (click)="editReferral(this.student.referrals.length - 1 - i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-button *ngIf="i < this.student.referrals.length - 1" color="warn" type="button" (click)="removeReferral(this.student.referrals.length - 1 - i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>


                    </ng-container>
                </div>
                <div class="full"><hr /></div>
            </ng-container>
            <div class="form-section">
                <div>
                    <mat-form-field>
                        <mat-label>Service Coordinator</mat-label>
                        <mat-select [(ngModel)]="referral.coordinator" name="coordinator" [required]="this.student.referrals.length < 1">
                            <mat-option *ngFor="let coordinator of this.validValueService.coordinators" [value]="coordinator">
                                {{coordinator}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Service Location</mat-label>
                        <mat-select [(ngModel)]="referral.location" name="location" [required]="this.student.referrals.length < 1">
                            <mat-option *ngFor="let location of this.validValueService.locations" [value]="location">
                                {{location}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="large-field">
                        <mat-label>Eligibility Status</mat-label>
                        <mat-select [(ngModel)]="referral.status" name="status" [required]="this.student.referrals.length < 1">
                            <mat-option *ngFor="let status of this.validValueService.statuses" [value]="status">
                                {{status}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="medium-field">
                        <mat-label>Referral Source</mat-label>
                        <mat-select [(ngModel)]="referral.source" name="sources" [required]="this.student.referrals.length < 1">
                            <mat-option *ngFor="let source of this.validValueService.sources" [value]="source">
                                {{source}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="full"><hr /></div>

                <div>
                    <mat-form-field>
                        <mat-label>Referral Date</mat-label>
                        <input matInput [disabled]="!this.student.birthDate" [(ngModel)]="referral.date" [matDatepicker]="referralDate" [min]="this.student.birthDate" name="Referral Date" (dateInput)="populateReferralDate()" [required]="this.student.referrals.length < 1">
                        <mat-hint>MM/DD/YYYY (Requires Birth Date)</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="referralDate"></mat-datepicker-toggle>
                        <mat-datepicker #referralDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Referral Age</mat-label>
                        <input matInput type="text" [(ngModel)]="referral.age" name="Referral Age" disabled>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <mat-label>Initial Eval Date</mat-label>
                        <input matInput [matDatepicker]="initialEval" [(ngModel)]="referral.initialEvalDate" [min]="referral.date" (dateInput)="setEvalDate()" name="Initial Eval Date" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="initialEval"></mat-datepicker-toggle>
                        <mat-datepicker #initialEval></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Initial IFSP</mat-label>
                        <input matInput [matDatepicker]="initialIFSP" name="Initial IFSP" [(ngModel)]="referral.initialIFSPDate" (dateChange)="intialIFSPChange()" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="initialIFSP"></mat-datepicker-toggle>
                        <mat-datepicker #initialIFSP></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Last IFSP</mat-label>
                        <input matInput [matDatepicker]="lastIFSP" name="Last IFSP" [(ngModel)]="referral.lastIFSPDate" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="lastIFSP"></mat-datepicker-toggle>
                        <mat-datepicker #lastIFSP></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Length of Service</mat-label>
                        <input matInput type="text" [(ngModel)]="referral.lengthOfService" name="Length of Service" disabled>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="medium-field">
                        <mat-label>Days from Referral to Initial Eval</mat-label>
                        <input matInput type="text" [(ngModel)]="referral.daysFromRef" name="Days From Referral To Initial Eval" disabled>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Current IFSP</mat-label>
                        <input matInput [matDatepicker]="currentIFSP" name="Current IFSP" [(ngModel)]="referral.currentIFSPDate" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="currentIFSP"></mat-datepicker-toggle>
                        <mat-datepicker #currentIFSP></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Measurement 1 Due</mat-label>
                        <input matInput [matDatepicker]="measureOneDue" name="Measurement 1 Date" [(ngModel)]="referral.measurement1Date" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="measureOneDue"></mat-datepicker-toggle>
                        <mat-datepicker #measureOneDue></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Measurement 1</mat-label>
                        <input matInput [matDatepicker]="measureOne" name="Measurement 1" [(ngModel)]="referral.measurement1" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="measureOne"></mat-datepicker-toggle>
                        <mat-datepicker #measureOne></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Measurement 2</mat-label>
                        <input matInput [matDatepicker]="measureTwo" name="Measurement 2" [(ngModel)]="referral.measurement2" [disabled]="!referral.date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="measureTwo"></mat-datepicker-toggle>
                        <mat-datepicker #measureTwo></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="full"><hr /></div>

                <div>
                    <mat-form-field>
                        <mat-label>Exit Date</mat-label>
                        <input matInput [matDatepicker]="exitDate" [(ngModel)]="referral.exitDate" name="Exit Date">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="exitDate"></mat-datepicker-toggle>
                        <mat-datepicker #exitDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="large-field">
                        <mat-label>Reason for Exit</mat-label>
                        <mat-select id="exitReason" [(ngModel)]="referral.exitReason" name="Exit Reasons" #exitReason="ngModel">
                            <mat-option *ngFor="let reason of this.validValueService.exitReasons" [value]="reason">
                                {{reason}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="exitReason.invalid">Exit Reason is Required</mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Months After Exit</mat-label>
                        <input matInput type="text" [(ngModel)]="referral.monthsAfterExit" name="Months After Exit" disabled>
                    </mat-form-field>
                </div>
            </div>

            <div>
                <mat-action-row>
                    <button mat-button color="primary" type="button" (click)="submitReferral()" [disabled]="(!this.referral.isValid || !canAddReferral()) && (this.referral.isValid && this.referralIndex == null)">Submit Referral</button>
                </mat-action-row>
            </div>
        </mat-expansion-panel>

        <!-- Note Panel -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Notes
                </mat-panel-title>
                <mat-panel-description>
                    Extra Information Regarding This Student
                    <mat-icon>note</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>


            <div class="form-section">
                <div>
                    <mat-form-field>
                        <mat-label>Note Type</mat-label>
                        <mat-select [(ngModel)]="note.type" name="noteType" (selectionChange)="onNoteTypeChange()">
                            <mat-option *ngFor="let noteType of this.validValueService.noteTypes" [value]="noteType.type">
                                {{noteType.type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="xlarge-field">
                        <mat-label>Note Description</mat-label>
                        <textarea matInput #noteDescription [(ngModel)]="note.description" name="Note Description" maxlength="500" cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="1"
                                  cdkAutosizeMaxRows="5"></textarea>
                        <mat-hint align="start">{{this.noteHint}}</mat-hint>
                        <mat-hint align="end">{{noteDescription.value.length}} / 500</mat-hint>
                    </mat-form-field>
                </div>
                <div>
                    <input type="file" hidden #fileInput (change)="onFileSelected($event)" />
                    <button id="file-input" type="button" mat-button color="primary" (click)="fileInput.click()">
                        Attach File
                    </button>
                </div>
                <div>
                    <p>{{this.note.attachment}}</p>
                </div>
            </div>
            <ng-container *ngIf="student.notes.length >= 1">
                <h1 class="center">Notes</h1>
                <div class="notes-container">
                    <div>
                        <b>Note Type</b>
                    </div>
                    <div>
                        <b>Note Description</b>
                    </div>
                    <div>
                        <b>File</b>
                    </div>
                    <div>
                        <b></b>
                    </div>
                    <ng-container *ngFor="let note of student.notes; index as i">
                        <div>
                            {{note.type}}
                        </div>
                        <p class="note-description">
                            {{note.description}}
                        </p>
                        <div>
                            <button mat-button color="primary" type="button" (click)="downloadNoteAttachment(note.attachment)" [disabled]="!note.id">{{note.attachment}}</button>
                        </div>
                        <button mat-button color="warn" (click)="removeNote(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </div>

            </ng-container>

            <div>
                <mat-action-row>
                    <button mat-button color="primary" type="button" (click)="addNote()">Add Note</button>
                </mat-action-row>
            </div>
        </mat-expansion-panel>

    </mat-accordion>
    <div>
        <mat-action-row>
            <button mat-button color="primary" type="submit">Submit</button>
        </mat-action-row>
    </div>
</form>
