<div class="dialog-container">
    <h1 class="title">Potential Duplicate Record</h1>
    <div class="student-comparison">
        <p></p>
        <p>Existing Student</p>
        <p>New Student</p>

        <p><b>First Name:</b></p>
        <p>{{data.student.firstName}}</p>
        <p>{{data.newStudent.firstName}}</p>

        <p><b>Last Name:</b></p>
        <p>{{data.student.lastName}}</p>
        <p>{{data.newStudent.lastName}}</p>

        <p><b>Birthdate:</b></p>
        <p>{{data.student.birthDate | date: 'yyyy-MM-dd'}}</p>
        <p>{{data.newStudent.birthDate | date: 'yyyy-MM-dd'}}</p>

        <p><b>Child Plus Id:</b></p>
        <p>{{data.student.childPlusId}}</p>
        <p>{{data.newStudent.childPlusId}}</p>
    </div>

    <div class="button-container">
        <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Submit Anyways</button>
        <button mat-button (click)="viewNewRecord()" color="secondary" cdkFocusInitial>View Record</button>
        <button mat-button [mat-dialog-close]="false" color="warn" cdkFocusInitial>Return to Form</button>
    </div>

</div>
