<h1>Welcome, {{username}}</h1>
<hr />

<h1 class="center">Early Learning Program</h1>
<br />

<div class="search-download-container">
    <mat-form-field class="search-bar">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter()" [(ngModel)]="this.filterValue" #input type="text">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="this.showInactive" (change)="applyFilter()">
        Show Inactive
    </mat-checkbox>
    <button class="download-button" mat-button color="primary" (click)="export()" title="Download current view as Excel file">
        <mat-icon class="download-icon">file_download</mat-icon>
    </button>
</div>



<!-- <div class="students-container">
    <div class="headers student-row">
        <h4 class="header">Name</h4>
        <h4>ELID</h4>
        <h4>Child Plus ID</h4>
        <h4>Age</h4>
        <h4>Gender</h4>
        <h4>Enrolled On</h4>
        <div></div>
    </div>

    <br />
    <div *ngFor="let student of this.students; index as i" class="student-row">
        <h4>{{student.firstName}} {{student.lastName}}</h4>
        <h4>{{student.id}}</h4>
        <h4>Child Plus ID</h4>
        <h4>{{student.age}}</h4>
        <h4>{{student.gender}}</h4>
        <h4>{{student.enrolledOn | date: 'MM/dd/yyyy'}}</h4>
        <div><button mat-button color="primary" (click)="this.editStudent(student.id)">Edit</button></div>
    </div>
</div> -->


<ng-container *ngIf="this.loading">
    <mat-spinner class="loading"></mat-spinner>
</ng-container>

<table *ngIf="!this.loading"
       mat-table
       [dataSource]="this.dataSource"
       matSort
       #studentSort="matSort"
       matSortActive="id"
       matSortDirection="desc"
       class="mat-elevation-z8 demo-table">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
        <td mat-cell *matCellDef="let student"> {{student.id}} </td>
    </ng-container>

    <!-- ChildPlus Id Column -->
    <ng-container matColumnDef="childPlusId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Child Plus Id</th>
        <td mat-cell *matCellDef="let student"> {{student.childPlusId}} </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let student"> {{student.firstName}}</td>
    </ng-container>

    <!-- School Year Column -->
    <ng-container matColumnDef="schoolYear">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> School Year </th>
        <td mat-cell *matCellDef="let student"> {{student.schoolYear}} </td>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let student">{{student.lastName}} </td>
    </ng-container>

    <!-- BirthDate Column -->
    <ng-container matColumnDef="birthDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Birth Date </th>
        <td mat-cell *matCellDef="let student"> {{student.birthDate | date: 'yyyy-MM-dd'}} </td>
    </ng-container>

    <!-- Age Column -->
    <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Age (M) </th>
        <td mat-cell *matCellDef="let student"> {{student.age}} </td>
    </ng-container>

    <!-- Gender Column -->
    <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
        <td mat-cell *matCellDef="let student"> {{student.gender}} </td>
    </ng-container>

    <!-- Enrolled On Column -->
    <ng-container matColumnDef="enrolledOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Enrolled On </th>
        <td mat-cell *matCellDef="let student"> {{student.enrolledOn | date: 'yyyy-MM-dd'}} </td>
    </ng-container>

    <!-- active Column -->
    <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let student">
            <ng-container *ngIf="student.isActive; else notActive">
                <mat-icon color="primary">checkmark</mat-icon>
            </ng-container>
            <ng-template #notActive>
                <mat-icon color="warn">close</mat-icon>
            </ng-template>
        </td>
    </ng-container>


    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let student">
            <div>
                <button mat-button color="primary" title="Edit Record" (click)="this.editStudent(student.id)">
                    <mat-icon class="download-icon">edit</mat-icon>
                </button>
                <!--                 <button mat-button color="primary" title="Delete Record" (click)="this.editStudent(student.id)">
                                    <mat-icon class="download-icon" color="warn">delete</mat-icon>
                                </button> -->
            </div>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons></mat-paginator>
