import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StudentRecord } from '../../model/student-record';
import { Router } from '@angular/router';

@Component({
  selector: 'app-duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.css']
})
export class DuplicateDialogComponent {
  constructor(public dialogRef: MatDialogRef<DuplicateDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { student: StudentRecord, newStudent: StudentRecord }) { }

  viewNewRecord() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/edit/${this.data.student.id}`])
    );

    window.open(url, '_blank');
  }
}
