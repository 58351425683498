<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
        <div class="container">
            <a class="navbar-brand" [routerLink]="['/dashboard']"><img class="nav-image" src="../../assets/images/cnmi-logo-large.png" alt="CNMI Logo"/></a>
            <button class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-label="Toggle navigation"
                    [attr.aria-expanded]="isExpanded"
                    (click)="toggle()">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div *ngIf="username !== null" class="navbar-collapse collapse d-sm-inline-flex justify-content-end"
                 [ngClass]="{ show: isExpanded }">
                <ul class="navbar-nav flex-grow">
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark" [routerLink]="['/dashboard']">Dashboard</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark" [routerLink]="['/create']">New Enrollment</a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['link-active']">
                        <a class="nav-link text-dark" [routerLink]="['/logout']">Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
