export class Referral {
  id?: number;
  recordId?: number;
  coordinator: string = '';
  location: string = '';
  status: string = '';
  source: string = '';
  date?: Date;
  age?: number;
  initialEvalDate?: Date;
  initialIFSPDate?: Date;
  lastIFSPDate?: Date;
  lengthOfService?: number;
  daysFromRef: number = 0;
  currentIFSPDate?: Date;
  measurement1Date?: Date;
  measurement1?: Date;
  measurement2?: Date;
  exitDate?: Date;
  monthsAfterExit: number = 0;
  exitReason: string = '';

  constructor(ref?: Referral) {
    this.id = ref?.id;
    this.recordId = ref?.recordId;
    this.coordinator = ref?.coordinator ?? '';
    this.location = ref?.location ?? '';
    this.status = ref?.status ?? '';
    this.source = ref?.source ?? '';
    this.date = ref?.date;
    this.age = ref?.age;
    this.initialEvalDate = ref?.initialEvalDate;
    this.initialIFSPDate = ref?.initialIFSPDate;
    this.lastIFSPDate = ref?.lastIFSPDate;
    this.lengthOfService = ref?.lengthOfService;
    this.daysFromRef = ref?.daysFromRef ?? 0;
    this.currentIFSPDate = ref?.currentIFSPDate;
    this.measurement1 = ref?.measurement1;
    this.measurement1Date = ref?.measurement1Date;
    this.measurement2 = ref?.measurement2;
    this.exitDate = ref?.exitDate;
    this.exitReason = ref?.exitReason ?? '';
    this.monthsAfterExit = ref?.monthsAfterExit ?? 0;
  }

  isValid(): boolean {
    if (this.coordinator == '' || this.location == '' ||
      this.status == '' || this.source == '' || this.date == null) {
      return false;
    }

    return true;
  };

  invalidFields(): string[] {
    let invalidFields: string[] = [];
    this.coordinator == '' ? invalidFields = invalidFields.concat('Service Coordinator') : '';
    this.location == '' ? invalidFields = invalidFields.concat('Service Location') : '';
    this.status == '' ? invalidFields = invalidFields.concat('Eligibility Status') : '';
    this.source == '' ? invalidFields = invalidFields.concat('Referral Source') : '';
    this.date == null ? invalidFields = invalidFields.concat('Referral Date') : '';

    return invalidFields;
  };
}
