import { Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  username = localStorage.getItem("username");
  constructor(private authService: AuthenticationService,
  private router: Router) {

  }

  ngOnInit() {
    this.router.navigate(['/']);
  }
}
