import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { distinctUntilChanged } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  title: string = environment.appName;
  public errorMessage: string = '';
  showError: boolean = true;
  isLoggingIn: boolean = false;

  constructor(private authService: AuthenticationService,
    private router: Router,
    protected dialog: MatDialog,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signOn")!,
        { theme: 'outline', size: 'large', width: 450 }
      );

      window.google.accounts.id.disableAutoSelect();

      window.google.accounts.id.prompt((notification: PromptMomentNotification) => { });
    }
  }

  handleResponse(response: CredentialResponse) {
    this.isLoggingIn = true;
    this.cdr.detectChanges();

    this.authService.login(response.credential).subscribe({
      next: (res) => {
        this.isLoggingIn = false;
        localStorage.setItem("token", res.token);
        localStorage.setItem("username", res.username);
        this.router.navigate(['/dashboard']).then(() => window.location.reload());
      },
      error: (error) => {
        let msg;
        typeof(error.error) == 'string' ? msg = ['Unable to log in', error.error] :
          msg = ['Unable to log in', 'Name: ' + error.name, 'Status Text: ' + error.status + ' ' + error.statusText];
        this.isLoggingIn = false;
        this.router.navigate(['/login']);
        let dialogRef = this.dialog.open(MessageDialogComponent, {
          data: { messages: msg}
        });
      }
    });

  }

}
