import { Component, ViewChild } from '@angular/core';
import { ValidValueService } from '../../services/valid-value.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Referral } from '../../model/referral';
import { MatAccordion } from '@angular/material/expansion';
import { StudentRecord } from '../../model/student-record';
import { StudentService } from '../../services/student.service';
import { NgForm } from '@angular/forms';
import { Note } from '../../model/note';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageDialogComponent } from '../../dialogs/message-dialog/message-dialog.component';
import { FileService } from '../../services/file.service';
import { NoteType } from '../../model/note-type';
import { ChildPlusStudent } from '../../model/childplus-student';
import { StagingService } from '../../services/staging.service';
import { DuplicateDialogComponent } from '../../dialogs/duplicate-dialog/duplicate-dialog.component';
import { mergeMap, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-student-form',
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.css'],
})
export class StudentFormComponent {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild(NgForm) form!: NgForm;

  studentId?: number;
  student: StudentRecord = new StudentRecord();
  childPlusStudents: ChildPlusStudent[] = [];
  referralIndex?: number;

  referral: Referral;
  note: Note;
  noteHint: string = '';
  attachment?: File;
  attachments: File[] = [];
  today: Date = new Date();

  duplicateRecord = false;


  constructor(protected validValueService: ValidValueService,
    private studentService: StudentService,
    private stagingService: StagingService,
    private fileService: FileService,
    private router: Router,
    private route: ActivatedRoute,
    protected dialog: MatDialog) {

    this.referral = new Referral();
    this.note = new Note();
  }

  public SubmitStudent(form: NgForm) {
    let submitMessages: string[] = [];
    if (form.valid) {
      console.log("Submitting");

      //If a new student record is being created
      if (this.student.id == null || this.student.id == undefined) {
        submitMessages = ['Entry has been submitted.'];

        //Check for duplicates
        this.studentService.checkDuplicate(this.student).pipe(
          switchMap(duplicateStudent => {
            if (duplicateStudent != null) {
              let duplicateDialogRef = this.dialog.open(DuplicateDialogComponent, {
                data: { student: duplicateStudent, newStudent: this.student }
              });

              return duplicateDialogRef.afterClosed();
            }
            return of(true);
          }),
          switchMap(submitting => {
            if (submitting) {
              return this.studentService.newEnrollment(this.student).pipe(
                switchMap(studentId => {
                  this.attachments.forEach((attachment: File) => {
                    this.fileService.uploadNoteAttachment(studentId, attachment);
                  });

                  return of(studentId);
                }),
                switchMap(studentId => {
                  let dialogRef = this.dialog.open(MessageDialogComponent, {
                    data: { messages: submitMessages }
                  });
                  return dialogRef.afterClosed().pipe(switchMap(() => of(studentId)));
                })
              )
            }
            else {
              return of(submitting);
            }
          })
        ).subscribe(result => {
          if (result) {
            this.router.navigate(['/edit/' + result]);
          }
        });
      }

      //Else edit the student record
      else {
        let id = this.student.id
        submitMessages = ['Entry has been saved.']


        this.studentService.editStudent(this.student).subscribe({
          next: (res) => {
            this.attachments.forEach((attachment: File) => {
              this.fileService.uploadNoteAttachment(id, attachment).subscribe({
                next: (res) => {
                  console.log("Submitted File: " + attachment.name);
                },
                error: (err) => { console.log(err); }
              })
            });

            let dialogRef = this.dialog.open(MessageDialogComponent, {
              data: { messages: submitMessages }
            });
            dialogRef.afterClosed().subscribe(result => {
              this.router.navigate(['/edit/' + this.studentId]);
            });
          },
          error: (err) => { console.log(err) }
        });
      }


    }

    return;
  }

  addNewStudent() {

  }

  editStudent() {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.studentId = params['id'];
    });

    if (this.studentId) {
      this.studentService.getStudent(this.studentId).subscribe({
        next: (student) => {
          this.student = student;
          this.student.birthDate = new Date(this.student.birthDate?.toString()!);
        },
        error: (err) => { console.log(err) }
      });
    }


  }

  childPlusStudentSuggestion() {
    if (this.student.childPlusId != null) {
      this.stagingService.childPlusStudents(this.student.childPlusId).subscribe({
        next: (res) => {
          this.childPlusStudents = res;
        },
        error: (err) => { console.log(err); }
      })
    }
  }

  selectChildPlusStudent(student: ChildPlusStudent) {
    this.student.firstName = student.firstName;
    this.student.middleName = student.middleName;
    this.student.lastName = student.lastName;
    this.student.gender = student.gender;
    this.student.birthDate = student.birthDate;
    this.student.federalRace = student.federalRace;
  }

  populateBirthDate() {
    if (this.student.birthDate != null) {
      this.student.thirdBirthday = new Date(this.student.birthDate.getFullYear() + 3, this.student.birthDate.getMonth(), this.student.birthDate.getDate());
      this.student.age = this.getMonths(this.student.birthDate, new Date());
      this.referral.measurement1Date = new Date(this.student.birthDate.getFullYear(), this.student.birthDate.getMonth(), this.student.birthDate.getDate() + 180)


      if (this.referral.date != null) {
        this.referral.age = this.getMonths(this.student.birthDate, this.referral.date);
      }
    }

  }

  populateReferralDate() {
    if (this.referral.date != null && this.student.birthDate != null) {
      this.referral.age = this.getMonths(this.student.birthDate, this.referral.date);
    }
  }

  getDays(initialDate: Date, comparisonDate: Date) {
    let days = Math.floor((comparisonDate.getTime() - initialDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }

  getMonths(initialDate: Date, comparisonDate: Date) {
    var months = (comparisonDate.getFullYear() - initialDate.getFullYear()) * 12;
    months -= initialDate.getMonth();
    months += comparisonDate.getMonth();
    return months
  }

  submitReferral() {
    if (!this.referral.isValid()) {
      let dialogRef = this.dialog.open(MessageDialogComponent, {
        data: { messages: ['Please fill in the required fields:'].concat(this.referral.invalidFields()) }
      });
      return;
    }
    if (this.referralIndex != null) {
      this.student.referrals[this.referralIndex] = this.referral;
      this.referralIndex = undefined;
    }
    else {
      this.student.referrals = this.student.referrals.concat(this.referral);
    }

    this.referral = new Referral();
  }

  editReferral(index: number) {
    this.referral = new Referral(this.student.referrals[index]);
    this.referralIndex = index;
  }

  removeReferral(index: number) {
    this.student.referrals.splice(index, 1);
  }

  canAddReferral() {
    if (this.student.referrals.length == 0) return true;

    for (let referral of this.student.referrals) {
      if (referral.exitDate == null) return false;
    }

    return true;
  }

  setEvalDate() {
    if (this.referral.initialEvalDate != null) {
      this.referral.initialIFSPDate = new Date(this.referral.initialEvalDate);
      this.intialIFSPChange();
      this.referral.lastIFSPDate = new Date(this.referral.initialEvalDate);
      this.referral.lengthOfService = this.getMonths(this.referral.initialIFSPDate, new Date());
    }

  }

  intialIFSPChange() {
    if (this.referral.initialIFSPDate != null) {
      this.referral.lengthOfService = this.getMonths(this.referral.initialIFSPDate, new Date());

      if (this.referral.date != null) {
        this.referral.daysFromRef = this.getDays(this.referral.date, this.referral.initialIFSPDate);
      }
    }
  }



  /* Note Logic */

  addNote() {
    if (this.note.isValid()) {
      if (this.attachment) {
        let containsDuplicate = false;
        this.student.notes.forEach((note: Note) => {
          if (this.attachment?.name != '' && this.attachment?.name == note.attachment) containsDuplicate = true;
        });

        if (containsDuplicate) {
          let dialogRef = this.dialog.open(MessageDialogComponent, {
            data: { messages: ['File: ' + this.attachment.name + ' already exists.'] }
          });
          return;
        }
        this.attachments = this.attachments.concat(this.attachment);
      }
      this.student.notes = this.student.notes.concat(this.note);
      this.note = new Note();
    }
  }

  removeNote(index: number) {
    this.student.notes.splice(index, 1);
  }


  onFileSelected(event: any) {

    const file: File = event.target.files[0];

    if (file) {
      this.attachment = file;
      this.note.attachment = file.name;

      /*      const formData = new FormData();
      
            formData.append("thumbnail", file);
      
            const upload$ = this.http.post("/api/thumbnail-upload", formData);
      
            upload$.subscribe();*/
    }
  }

  downloadNoteAttachment(attachment: string) {
    if (this.student.id != null) {
      this.fileService.downloadNoteAttachment(this.student.id, attachment).subscribe({
        next: (res) => {
          var data = window.URL.createObjectURL(res);
          var link = document.createElement("a");
          link.href = data;
          link.download = attachment;
          link.click();
          return res;
        },
        error: (err) => { console.log(err) }
      });
    }

    return
  }

  onNoteTypeChange() {
    this.validValueService.noteTypes.forEach((type: NoteType) => {
      if (type.type == this.note.type) {
        this.noteHint = type.hint
      }
    })
  }
}
