import { Note } from "./note";
import { Referral } from "./referral";

export class StudentRecord {
  id?: number;
  childPlusId?: number;
  isActive: boolean = true;
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  suffix: string = '';
  birthDate?: Date;
  thirdBirthday?: Date;
  age: number = 0;
  gender: string = '';
  federalRace: string = '';
  localRace: string = '';
  referrals: Referral[] = [];
  notes: Note[] = [];

  constructor() {

  }



}
