import { Injectable } from '@angular/core';
import { StudentRecord } from '../model/student-record';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShortStudentRecord } from '../model/short-student-record';
import { ChildPlusStudent } from '../model/childplus-student';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  apiUrl: string = environment.apiUrl + "Student/";
  studentBeingEdited?: number
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) { }

  newEnrollment(student: StudentRecord): Observable<number> {
    return this.http.post<number>(this.apiUrl + 'new-enrollment', this.stringify(student), { headers: this.httpHeaders });
  }

  getStudents(): Observable<StudentRecord[]> {
    var students = this.http.get<StudentRecord[]>(this.apiUrl + "student-records", { headers: this.httpHeaders });
    return students;
  }

  getStudentsSummary(): Observable<ShortStudentRecord[]> {
    var students = this.http.get<ShortStudentRecord[]>(this.apiUrl + "student-records-summary", { headers: this.httpHeaders });
    return students;
  }

  getStudent(id: number): Observable<StudentRecord> {
    var student = this.http.post<StudentRecord>(this.apiUrl + "student-record", id, { headers: this.httpHeaders });
    return student;
  }

  editStudent(student: StudentRecord): Observable<StudentRecord> {
    return this.http.post<StudentRecord>(this.apiUrl + "edit-student", this.stringify(student), { headers: this.httpHeaders });
  }

  checkDuplicate(student: StudentRecord): Observable<StudentRecord> {
    return this.http.post<StudentRecord>(this.apiUrl + "check-duplicate", this.stringify(student), { headers: this.httpHeaders });
  }

  stringify(obj: any) {
    let cache: any[] = [];
    return JSON.stringify(obj, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
  }
}
