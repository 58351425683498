export class Note {
  id?: number;
  type: string = '';
  description: string = '';
  attachment: string = '';

  isValid() {
    if (this.type != '' && this.description != '') return true;
    return false;
  }
}
