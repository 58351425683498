<div id="login-container">
    <img id="logo" src="assets/images/cnmi-logo.png" alt="CNMI Logo"/>
    <h1>Welcome to the {{this.title}}</h1>

    <div id="signOn">

    </div>

    <mat-spinner *ngIf="this.isLoggingIn"></mat-spinner>
</div>
