import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StudentService } from '../services/student.service';
import { ShortStudentRecord } from '../model/short-student-record';
import * as xlsx from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { MatTableDataSource, _MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Referral } from '../model/referral';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements AfterViewInit {
  title = 'Early-Learning-Export';
  fileName = 'EL-Export';
  username = localStorage.getItem("username");
  students: ShortStudentRecord[] = [];
  dataSource = new MatTableDataSource<ShortStudentRecord>();

  showInactive: boolean = false;
  filterValue: string = '';
  loading = true;
  displayedColumns: string[] = ['id', 'childPlusId', 'schoolYear', 'firstName', 'lastName', 'birthDate', 'age', 'gender', 'enrolledOn', 'isActive', 'edit'];


  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent = new PageEvent();

  @ViewChild('paginator') paginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild('studentSort') set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(private router: Router,
    protected studentService: StudentService,
    protected dialog: MatDialog,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.loading = true;
    this.studentService.getStudentsSummary().subscribe({
      next: (students) => {
        this.students = students
        this.dataSource = new MatTableDataSource<ShortStudentRecord>(this.students);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.dashboardFilterPredicateActive
        // Set this once after your datasource have been initialized, and don't change it afterwards. It needs to handle all combinantions of different filters that you apply.
        this.loading = false;
        this.applyFilter();
      },
      error: (err) => {
        this.dialog.open(MessageDialogComponent, {
          data: { messages: ['Records could not be retrieved.'] }
        });
        this.loading = false;
        this.cd.detectChanges();
      }
    });
  }

  editStudent(id: number) {
    this.studentService.studentBeingEdited = id;
    this.router.navigate(['/edit', id]);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  applyFilter() {
    this.showInactive ? this.dataSource.filterPredicate = this.dashboardFilterPredicateInactive : this.dataSource.filterPredicate = this.dashboardFilterPredicateActive
    this.dataSource.filter = this.filterValue == '' ? ' ': this.filterValue.trim().toLowerCase();
  }

  export() {
    this.studentService.getStudents().subscribe({
      next: (students) => {
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        let records: any[] = [];

        students.forEach((student, index) => {
          if (student.referrals.length == 0) {
            records.push({
              "Id": student.id,
              "First Name": student.firstName,
              "Middle Name": student.middleName,
              "Last Name": student.lastName,
              "Suffix": student.suffix,
              "Birth Date": student.birthDate ? new Date(student.birthDate).toLocaleDateString() : student.birthDate,
              "Third Birthday": student.thirdBirthday ? new Date(student.thirdBirthday).toLocaleDateString() : student.thirdBirthday,
              "Age": student.age,
              "Gender": student.gender,
              "Federal Race": student.federalRace,
              "Local Race": student.localRace
            });
          }
          else {
            student.referrals.forEach((referral) => {
              records.push({
                "Id": student.id,
                "First Name": student.firstName,
                "Middle Name": student.middleName,
                "Last Name": student.lastName,
                "Suffix": student.suffix,
                "Birth Date": student.birthDate ? new Date(student.birthDate).toLocaleDateString() : student.birthDate,
                "Third Birthday": student.thirdBirthday ? new Date(student.thirdBirthday).toLocaleDateString() : student.thirdBirthday,
                "Age": student.age,
                "Gender": student.gender,
                "Federal Race": student.federalRace,
                "Local Race": student.localRace,
                "Referral Date": referral.date ? new Date(referral.date).toLocaleDateString() : referral.date,
                "Referral Age": referral.age,
                "Coordinator": referral.coordinator,
                "Location": referral.location,
                "Status": referral.status,
                "Source": referral.source,
                "Initial Evaluation": referral.initialEvalDate ? new Date(referral.initialEvalDate).toLocaleDateString() : referral.initialEvalDate,
                "Days from Referral to Initial Eval": referral.daysFromRef,
                "Length of Service": referral.lengthOfService,
                "Initial IFSP": referral.initialIFSPDate ? new Date(referral.initialIFSPDate).toLocaleDateString() : referral.initialIFSPDate,
                "Last IFSP": referral.lastIFSPDate ? new Date(referral.lastIFSPDate).toLocaleDateString() : referral.lastIFSPDate,
                "Current IFSP": referral.currentIFSPDate ? new Date(referral.currentIFSPDate).toLocaleDateString() : referral.currentIFSPDate,
                "Measurement 1": referral.measurement1 ? new Date(referral.measurement1).toLocaleDateString() : referral.measurement1,
                "Measurement 1 Date": referral.measurement1Date ? new Date(referral.measurement1Date).toLocaleDateString() : referral.measurement1Date,
                "Measurement 2": referral.measurement2 ? new Date(referral.measurement2).toLocaleDateString() : referral.measurement2,
                "Exit Date": referral.exitDate ? new Date(referral.exitDate).toLocaleDateString() : referral.exitDate,
                "Exit Reason": referral.exitReason,
                "Months After Exit": referral.monthsAfterExit
              });
            })
          }

        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(records);
        xlsx.utils.book_append_sheet(wb, ws, 'Records');
        xlsx.writeFile(wb, this.fileName + '-' + new Date().toLocaleDateString() + '.xlsx');
      },
      error: (err) => {
        this.dialog.open(MessageDialogComponent, {
          data: { messages: ['Records could not be retrieved.'] }
        });
      }
    });
  }


  dashboardFilterPredicateActive(data: any, filter: string) {
    filter == " " ? filter = "" : filter = filter;
    let filterComparison = "";
    for (let key in data) {filterComparison += data[key] ? data[key].toString() : '';}
    filterComparison = filterComparison.toLowerCase().trim();

    return filterComparison.indexOf(filter) > -1 && data.isActive;
  }

  dashboardFilterPredicateInactive(data: any, filter: string) {
    let filterComparison = "";
    for (let key in data) { filterComparison += data[key] ? data[key].toString() : ''; }
    filterComparison = filterComparison.toLowerCase().trim();

    return filterComparison.indexOf(filter.trim()) > -1;
  }
}
